// 公共组件
import commonComponents from '@/components/common'
// import download from './download'

const commonPlugins = {
  install: function (vue) {
    // 公共组件全局注册
    commonComponents.map((component) => {
      const name = component.default.componentName
      vue.component(name, component.default)
    })

    // vue.prototype.$download = download
  }
}

export default commonPlugins
