// echarts 按需加载
import * as echarts from 'echarts/core'
// 引入主题配置文件
import echartsCustom from '@/utils/echarts_custom.json'
import echartsDark from '@/utils/echarts_dark.json'

import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
  ParallelComponent,
  TimelineComponent,
  MarkPointComponent,
  VisualMapComponent,
  GeoComponent,
  PolarComponent,
  RadarComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent, DataZoomComponent,
} from 'echarts/components'
import { BarChart, LineChart, PieChart, RadarChart, HeatmapChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

// 注册必须的组件
echarts.use([
  BarChart,
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  MarkAreaComponent,
  TimelineComponent,
  VisualMapComponent,
  MarkPointComponent,
  GeoComponent,
  ParallelComponent,
  PieChart,
  PolarComponent,
  RadarChart,
  RadarComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  HeatmapChart,
  DataZoomComponent,
])
// 引入echarts全局自定义主题
echarts.registerTheme('echarts_custom', echartsCustom)
echarts.registerTheme('echarts_dark', echartsDark)

// 导出
// export default echarts
export function setEcharts(app) {
  app.config.globalProperties.$echarts = echarts;
  // app.use(echarts)
}
