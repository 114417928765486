import { createStore } from 'vuex'
import getters from "@/store/getters";
import dict from "./modules/dict";
import user_info from "./modules/user";

export default createStore({
  state: {
    loading: false,
    loadingWhiteList: []
  },
  mutations: {
  },
  actions: {
    updateLoading({ state }, loading) {
      state.loading = loading
    }
  },
  modules: {
    dict,
    user_info,
  },
  getters
})
