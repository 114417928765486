import {homeRoute} from "@/router/routes/basic";

const contexts = require.context('./modules/',true, /\.js$/)
const routeModuleList = []
// 加入到路由集合中
contexts.keys().forEach((key) => {
    const mod = contexts(key).default || {}
    const modList = Array.isArray(mod) ? [...mod] : [mod]
    routeModuleList.push(...modList)
})

export const routes = [homeRoute,...routeModuleList]
