const routes = {
    path: '/charging-station/work-order',
    name: 'work_order',
    component: () => import('@/view/work_order/index.vue'),
    meta: {
        title: '工单管理',
    },
}

export default routes
