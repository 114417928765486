const routes = {
    path: '/charging-station/node-template',
    name: 'node_template',
    component: () => import('@/view/node_template/index.vue'),
    meta: {
        title: '节点模版管理',
    },
}

export default routes
