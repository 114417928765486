const routes = {
    path: '/charging-station/device-location',
    name: 'device_location',
    component: () => import('@/view/device_location/index.vue'),
    meta: {
        title: '设备定位',
    },
}

export default routes
