const routes = {
    path: '/routing_jump',
    name: 'routing_jump',
    component: () => import('@/view/routing_jump/RoutingJump.vue'),
    meta: {
        title: '路由跳转',
    },
}

export default routes
