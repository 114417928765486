<template>
  <el-form :model="formModel" :label-width="labelWidth" ref="baseForm">
    <el-row type="flex" align="top" :gutter="32">
      <template v-for="item in option.formItems" :key="item.key">
        <el-col
          v-if="
            getShowCondition(setPropsDefault(item.props, 'show', undefined))
          "
          :span="setPropsDefault(item.props, 'span', 12)"
        >
          <el-form-item
            v-if="!item.customRender"
            :label="item.label"
            :prop="item.key"
            :rules="setPropsDefault(item.props, 'rules', [])"
          >
            <!-- 输入框 -->
            <el-input
              v-if="item.type === 'input'"
              v-model.trim="getModel(item.key)[getLastKey(item.key)]"
              :type="setPropsDefault(item.inputProps, 'type', 'text')"
              :maxLength="setPropsDefault(item.inputProps, 'maxLength', null)"
              :disabled="setDisabled(item) || formDisabled"
              :placeholder="setPlaceHolder(item)"
              :rows="setPropsDefault(item.inputProps, 'rows', 3)"
              @change="
                (e) => {
                  itemChange(e, item)
                }
              "
              autocomplete="off"
            ></el-input>

            <!-- 选择框 -->
            <el-select
              v-if="item.type === 'select'"
              :disabled="setDisabled(item) || formDisabled"
              :mode="setPropsDefault(item.selectProps, 'mode', 'default')"
              :placeholder="setPlaceHolder(item)"
              :showSearch="
                setPropsDefault(item.selectProps, 'showSearch', false)
              "
              option-filter-prop="children"
              style="width: 100%"
              @change="
                (e) => {
                  itemChange(e, item)
                }
              "
              v-model="getModel(item.key)[getLastKey(item.key)]"
            >
              <el-option
                v-for="(optItem, optIndex) in item.selectProps.option"
                :value="
                  optItem[
                    setPropsDefault(item.selectProps, 'valueKey', 'itemValue')
                  ]
                "
                :key="optIndex"
                :label="
                  optItem[
                    setPropsDefault(item.selectProps, 'labelKey', 'itemName')
                  ]
                "
                :disabled="optItem.disabled === true"
                >{{
                  optItem[
                    setPropsDefault(item.selectProps, 'labelKey', 'itemName')
                  ]
                }}</el-option
              >
            </el-select>
            <!-- 日期 -->
            <el-date-picker
              v-if="item.type === 'date'"
              v-model="getModel(item.key)[getLastKey(item.key)]"
              :disabledDate="
                setPropsDefault(item.dateProps, 'disabledDate', null)
              "
              :disabled="setDisabled(item) || formDisabled"
              :placeholder="setPlaceHolder(item)"
              @change="
                (e) => {
                  itemChange(e, item)
                }
              "
            ></el-date-picker>
            <!-- 自定义 -->
            <template v-if="item.type === 'custom'">
              <slot :name="item.props.slot" />
            </template>
          </el-form-item>
          <!-- 全部自定义 -->
          <template v-else>
            <slot :name="item.customSlot" />
          </template>
        </el-col>
      </template>
    </el-row>
  </el-form>
</template>

<script>
import moment from 'moment'
const _ = require('lodash')
export default {
  name: 'BaseForm',
  props: {
    formModel: {
      type: Object
    },
    labelWidth: {
      type: String,
      default: '100px'
    },
    option: {
      type: Object,
      require: true
    },
    formDisabled: {
      type: Boolean,
      default: false
    },
    formMethod: {
      type: Function,
      default: null
    }
  },
  computed: {
    getShowCondition() {
      const formModel = this.formModel
      return function (option) {
        if (option === undefined) {
          return true
        }

        return option(formModel)
      }
    },
    setDisabled() {
      const formModel = this.formModel
      return function (option) {
        if (typeof option.disabled === 'undefined') {
          return false
        } else if (typeof option.disabled === 'boolean') {
          return option.disabled
        } else {
          // diabled为方法
          return option.disabled(formModel)
        }
      }
    }
  },
  watch: {},
  data() {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    setPropsDefault(obj, key, val) {
      return obj
        ? obj[key] || obj[key] === false || obj[key] === '' || obj[key] === 0
          ? obj[key]
          : val
        : val
    },
    getModel(key) {
      const keys = key.split('.')
      if (keys.length > 1) {
        const parentList = []
        keys.some((ele, index) => {
          if (index == 0) {
            parentList.push(this.formModel[keys[0]])
          } else {
            parentList.push(parentList[index - 1][ele])
          }
          if (index === keys.length - 2) {
            return true
          }
        })
        if (parentList.length > 1) {
          return parentList[parentList.length - 2]
        } else {
          return parentList[0]
        }
      } else {
        return this.formModel
      }
    },
    getLastKey(key) {
      const keys = key.split('.')
      return keys[keys.length - 1]
    },
    setPlaceHolder(item) {
      if (this.formDisabled) {
        return ''
      } else {
        let descText
        if (item.type === 'input' || item.type === 'textarea') {
          descText = '输入'
        } else {
          descText = '选择'
        }
        return item.placeholder || `请${descText}` + item.label
      }
    },
    itemChange(val, item) {
      if (item.onChange) {
        item.onChange(val, item)
      }
      this.$emit('modelChange', val, item)
    },
    // 重置model数据
    resetFields() {
      this.$refs.baseForm.resetFields()
    },
    // 校验表单
    async validate() {
      return new Promise((resolve, reject) => {
        this.$refs.baseForm.validate((valid) => {
          if (valid) {
            resolve('ok')
          } else {
            return false
          }
        })
      })
    },
    // 表单赋值 回显
    setFields(data) {
      this.option.formItems.forEach((item) => {
        _.set(this.formModel, item.key, _.get(data, item.key))
      })
    },
    // 表单提交
    submit() {
      return new Promise((resolve, reject) => {
        if (!this.formMethod) {
          console.error('未传递提交请求方法')
          reject('未传递提交请求方法')
          return
        }
        this.$refs.baseForm.validate(async (valid, err) => {
          if (valid) {
            let res = ''
            try {
              // 提交成功
              res = await this.formMethod(this.formModel)
              resolve(res)
            } catch (e) {
              // 提交失败， 返回错误信息
              this.$emit('submitFail', e)
              reject(e)
              return
            }
            this.$emit('submitSucc', res)
          } else {
            reject('请按要求填写表单')
            return false
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less"></style>
