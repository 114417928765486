const routes = {
    path: '/charging-station/dict-type',
    name: 'dict_type',
    component: () => import('@/view/dict/index.vue'),
    meta: {
        title: '字典类型',
    },
}

export default routes
