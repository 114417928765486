const routes = {
  path: '/node',
  children: [
    {
      path: 'charging-station/node',
      name: 'node',
      component: () => import('@/view/node/index.vue'),
      meta: {
        title: '节点',
      },
    },
  ],
}

export default routes
