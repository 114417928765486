const routes = {
    path: '/charging-station/inspection-record',
    name: 'inspectionRecord',
    component: () => import('@/view/inspection_record/index.vue'),
    meta: {
      title: '巡查记录',
    }
  }
  
  export default routes
  