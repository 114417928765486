const state = {
  user_info: {}
}
const mutations = {
  SET_USER: (state, userInfo) => {
    if (userInfo) {
      state.user_info = userInfo
    }
  },
  CLEAN_USER: (state) => {
    state.user_info = {}
  }
}

const actions = {
  // 设置用户
  setUser({ commit }, userInfo) {
    commit('SET_USER', userInfo)
  },
  // 清空用户
  cleanUser({ commit }) {
    commit('CLEAN_USER')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

