const routes = {
    path: '/',
    name: 'index',
    meta: {
        title: '路由跳转',
    },
    redirect: '/routing_jump'
}

export default routes
