import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
import {Loading} from "@element-plus/icons-vue";
import { ElLoading } from 'element-plus';
import {blobValidate, tansParams} from "@/utils/ruoyi";
import { saveAs } from 'file-saver'
import errorCode from '@/utils/errorCode'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000
})
let downloadLoadingInstance;
let loadingIndex = 0
// request interceptor
service.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token')
    if (token) {
      if (config.url.indexOf(process.env.VUE_APP_ALI_API) != -1||config.url.indexOf(process.env.VUE_APP_AC_ALI_API) != -1) {
        config.headers['auth-token'] = token
      } else {
        config.data = config.data ? { ...config.data, 'cf-token': token } : { 'cf-token': token }
      }
      if (config.url.indexOf(process.env.VUE_APP_ZHEN_API) > 0){
          config.headers['cf-token'] = ''
          config.headers['Content-Type'] = 'application/json'
          config.headers['Accept'] = '*/*'
      }
    } else {
      //   todo 异常处理
      // store.dispatch('user/logout')
    }

    // if (!window.vm.$store.state.app.loadingWhiteList.includes(config.url)) {
    //   loadingIndex++
    //   window.vm.$store.dispatch('app/updateLoading', true)
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    // debugger
    //   if (!window.vm.$store.state.app.loadingWhiteList.includes(response.config.url)) {
    //   loadingIndex = reduceLoading(loadingIndex)
    // }
    // 二进制数据则直接返回
    if (response.request.responseType ===  'blob' || response.request.responseType ===  'arraybuffer') {
      return response.data
    }

    if (res.code != 200 && res.code !== 0) {
      ElMessage.error(res.msg || res.message)
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    // if (!window.vm.$store.state.app.loadingWhiteList.includes(error.config.url)) {
    //   loadingIndex = reduceLoading(loadingIndex)
    // }
    ElMessage.error('请求错误')
    return Promise.reject(error)
  }
)

function reduceLoading(loadingIndex) {
  loadingIndex--
  if (loadingIndex <= 0) {
    window.vm.$store.dispatch('app/updateLoading', false)
  }
  return loadingIndex
}

// 通用下载方法
export function download(url, params, filename, config) {
    downloadLoadingInstance = ElLoading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
    return service.post(url, params, {
        transformRequest: [(params) => { return tansParams(params) }],
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
        ...config
    }).then(async (data) => {
        const isBlob = blobValidate(data);
        if (isBlob) {
            const blob = new Blob([data])
            saveAs(blob, filename)
        } else {
            const resText = await data.text();
            const rspObj = JSON.parse(resText);
            const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
            // Message.error(errMsg);
            this.$message({
                message: errMsg,
                type: 'error',
            })
        }
        downloadLoadingInstance.close();
    }).catch((r) => {
        console.error(r)
        // Message.error('下载文件出现错误，请联系管理员！')
        this.$message({
            message: '下载文件出现错误，请联系管理员！',
            type: 'error',
        })
        downloadLoadingInstance.close();
    })
}

export default service
