const routes = {
    path: '/charging-station/business-statistics',
    name: 'businessStatistics',
    component: () => import('@/view/business_statistics/index.vue'),
    meta: {
        title: '业务统计',
    },
}

export default routes
