const routes = {
  path: '/charging-station/project',
  name: 'project',
  component: () => import('@/view/project/index.vue'),
  meta: {
    title: '项目管理',
  }
}

export default routes
