<template>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'App',
  setup() {}
}
</script>

<style>
.el-select-dropdown {
  z-index: 3000 !important;
}
.el-date-editor input {
  padding-left: 28px !important;
}
</style>
