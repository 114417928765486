<template>
  <section ref="baseTable" class="dms-table">
    <!-- 搜索部分 -->
    <el-row
      v-if="searchInfo.length > 0"
      type="flex"
      align="center"
      class="table-search-row"
      :gutter="14"
    >
      <el-card shadow="never" class="table-search-card">
        <template v-for="(item, index) in searchInfo" :key="index">
          <el-col :span="6">
            <el-row :gutter="10" type="flex" align="middle">
              <el-col class="search-label" :span="6">
                {{ item.title }}:
              </el-col>
              <el-col :span="18">
                <el-input
                  v-model="searchData[item.key]"
                  :placeholder="'请输入' + item.title"
                >
                </el-input>
              </el-col>
            </el-row>
          </el-col>
        </template>
        <el-col :span="6">
          <el-button type="primary" @click="searchBtn">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </el-col>
      </el-card>
    </el-row>
    <div class="table-btn-list" v-if="tableBtn.length > 0">
      <el-button
        class="table-btn"
        v-for="(btn, index) in tableBtn"
        :key="index"
        :type="btn.type || 'primary'"
        @click="btn.handle()"
      >
        <i
          class="action-icon"
          v-if="btn.icon"
          :class="'el-icon-' + btn.icon"
        ></i>
        {{ btn.title }}
      </el-button>
    </div>
    <el-row class="table-container">
      <el-col :span="24">
        <el-table
          ref="commonTable"
          class="common-table"
          :border="true"
          :data="tableData"
          :row-key="rowKey"
        >
          <template v-for="item in tableColumns" :key="item.key">
            <el-table-column
              :prop="item.key"
              :label="item.title"
              :align="item.align || 'center'"
              :fixed="item.fixed || null"
              :width="item.width || null"
              :type="item.type == 'order' ? 'index' : null"
            >
              <template v-if="item.type" #default="scope">
                <span v-if="item.type == 'solt'">
                  <slot :name="item.props.slotName" :row="scope.row" />
                </span>
                <span v-if="item.type == 'action'">
                  <el-button
                    class="table-btn"
                    v-for="(btn, index) in item.props.btnList"
                    :key="index"
                    size="small"
                    :type="btn.type || 'primary'"
                    @click="btn.handle(scope.row)"
                  >
                    <i
                      class="action-icon"
                      v-if="btn.icon"
                      :class="'el-icon-' + btn.icon"
                    ></i>
                    {{ btn.title }}
                  </el-button>
                </span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </el-col>
      <el-col v-show="tableMethod" class="base-page">
        <el-pagination
          class="table-pagination"
          :current-page="pageNo"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        />
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  name: 'BaseTable',
  props: {
    // 表格主键
    rowKey: {
      type: String,
      default: 'id'
    },
    // 表格columns
    columns: {
      type: Array,
      default: () => []
    },
    sourceData: {
      // 表格数据
      type: Array,
      default: () => []
    },
    tableMethod: {
      // 表格列表request方法
      type: Function,
      default: null
    },
    tableBtn: {
      // 表格按钮
      type: Array,
      default: () => []
    }
  },
  watch: {
    columns: {
      immediate: true,
      handler(val) {
        if (val[0].key !== 'order') {
          this.tableColumns = [
            { title: '序号', key: 'order', type: 'order', width: 100 }
          ]
          val.forEach((ele) => {
            if (ele.key != 'keyword') {
              this.tableColumns.push(ele)
            }
          })
        } else {
          let list = []
          val.forEach((ele) => {
            if (ele.key != 'keyword') {
              list.push(ele)
            }
          })
          this.tableColumns = val
        }

        this.searchInfo = val.filter((ele) => ele.search === true)
        if (this.searchInfo.length > 0) {
          const info = {}
          this.searchInfo.forEach((ele) => {
            info[ele.key] = ''
          })
          this.searchData = info
        }
      }
    }
  },
  data() {
    return {
      pageSize: 10,
      pageNo: 0,
      total: 0,
      tableData: [],
      searchInfo: [],
      searchData: {},
      tableColumns: []
    }
  },
  created() {
    this.getPageList()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async getPageList() {
      if (this.tableMethod) {
        const data = await this.tableMethod({
          ...this.searchData,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          current: this.pageNo,
          size: this.pageSize
        })
        if (data.result) {
          this.tableData = data.result.records
          this.total = data.result.total
        } else {
          this.tableData = data.data.records
          this.total = data.data.total
        }
      }
    },
    searchBtn() {
      this.getPageList()
    },
    resetSearch() {
      for (const key in this.searchData) {
        this.searchData[key] = ''
      }
      this.getPageList()
    }
  }
}
</script>

<style scoped lang="less">
.table-search-card {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  margin: 0 8px 12px 8px;
  :deep(.el-card__body) {
    padding: 0;
  }
  .search-label {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.table-btn-list {
  margin-bottom: 12px;
}
.action-icon {
  margin-right: 10px;
}
.base-page {
  margin-top: 12px;
  .el-pagination {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
