const routes = {
  path: '/charging-station/dict-data/:dictId(\\d+)',
  name: 'dict',
  component: () => import('@/view/dict/data.vue'),
  meta: {
    title: '字典类型',
  },
}

export default routes
