import { createApp } from "vue";
import App from "./App.vue";
import Router from "./router";
import Store from "./store";
import "normalize.css/normalize.css";

import ElementPlus, { ElMessage, ElMessageBox } from "element-plus";
import localeZH from "element-plus/lib/locale/lang/zh-cn";
import "element-plus/theme-chalk/index.css";

import "./style/common.less";
import commonPlugins from "./plugin";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  selectDictLabels,
  handleTree,
} from "@/utils/ruoyi";
// import { download } from '@/utils/request'
import download from "./plugin/download";
import { getDicts } from "@/api/dict/data";
import { setupDict } from "@/components/DictData";
import { setEcharts } from "./utils/echarts";
import {registerGlobComp} from '@/core/register_glob_comp.js'
let app = null;
let appProps = {};
function render(props) {
  // 传递请求axios封装
  appProps = props;
  app = createApp(App);
  app.use(Router);
  app.use(Store);
  app.use(ElementPlus, { locale: localeZH, size: "default" });
  app.use(commonPlugins);
  // 注册全局组件
  registerGlobComp(app);
  setEcharts(app);
  // 字典的注册
  setupDict(app);
  app.config.globalProperties.$message = ElMessage;
  app.config.globalProperties.$messageBox = ElMessageBox;

  app.config.globalProperties.getDicts = getDicts;
  app.config.globalProperties.resetForm = resetForm;
  app.config.globalProperties.parseTime = parseTime;
  app.config.globalProperties.addDateRange = addDateRange;
  app.config.globalProperties.selectDictLabel = selectDictLabel;
  app.config.globalProperties.selectDictLabels = selectDictLabels;
  app.config.globalProperties.handleTree = handleTree;
  // app.config.globalProperties.download = download
  app.config.globalProperties.$download = download;
  // Register All Icons
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }

  app.mount(`#charging-station-app`);
}

if (window.__POWERED_BY_QIANKUN__) {
  // 动态设置 webpack publicPath，防止资源加载出错
  // eslint-disable-next-line no-undef
  console.log("资源", window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__);
  // eslint-disable-next-line no-undef
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
}

// 独立运行时，直接挂载应用
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}
/**
 * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
 * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
 */
export async function bootstrap() {
  //
}

/**
 * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
 */
export async function mount(props) {
  render(props);
}
/**
 * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
 */
export async function unmount() {
  app.$destroy();
  app = null;
}

// 手动调用钩子
export async function update(props) {}

export default app;
